<template>
  <section class="custom-border">
    <h2 class="subtitle">Generar resolución mensual</h2>
    <article class="columns is-multiline is-mobile">
      <div class="column is-narrow">
        <b-field label="Mes">
          <multiselect
            v-model="selectedMonth"
            :options="monthNames"
            :value-template="selectedMonth"
            placeholder="Seleccione mes"
            select-label=""
            selected-label="Seleccionado"
            deselect-label=""
            :allow-empty="false"
            @input="emitFilterEvent"
          >
            <template #noOptions> No existen datos </template>
            <span slot="noResult"> No se encontraron elementos. </span>
          </multiselect>
        </b-field>
      </div>

      <div class="column is-narrow">
        <b-field label="Año">
          <multiselect
            v-model="selectedYear"
            :options="years"
            placeholder="Seleccione año"
            select-label=""
            selected-label="Seleccionado"
            deselect-label=""
            :allow-empty="false"
            @input="emitFilterEvent"
          >
            <template #noOptions> No existen datos </template>
            <span slot="noResult"> No se encontraron elementos. </span>
          </multiselect>
        </b-field>
      </div>

      <div class="column is-flex is-align-items-flex-end">
        <b-tooltip
          v-if="administrativePermitsApproved.length === 0"
          :label="`No existen solicitudes de permisos aprobadas, para generar la resolución mensual de ${selectedMonth}-${selectedYear}.`"
          position="is-top"
          size="is-medium"
          append-to-body
          multilined
          style="width: 100%"
        >
          <div style="width: 100%">
            <b-button
              type="is-primary"
              icon-left="file-invoice"
              class="has-text-white expanded-button"
              :disabled="administrativePermitsApproved.length === 0"
              @click="openDocumentSelectionModal"
            >
              Generar resolución
            </b-button>
          </div>
        </b-tooltip>
        <b-button
          v-if="administrativePermitsApproved.length > 0"
          type="is-primary"
          icon-left="file-invoice"
          expanded
          class="has-text-white"
          @click="openDocumentSelectionModal"
        >
          Generar resolución
        </b-button>
      </div>
    </article>

    <b-modal v-model="isModalActive" :can-cancel="['escape']" has-modal-card>
      <div class="modal-card modal-width">
        <header class="modal-card-head">
          <div class="is-flex-grow-1 p-1">
            <p class="title is-size-4 mb-0">Generar resolución mensual</p>
            <p class="is-size-5 mb-1">
              {{ selectedMonth }} del año {{ selectedYear }}
            </p>
          </div>
          <button
            class="delete"
            aria-label="close"
            @click="isModalActive = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <h2 class="is-size-5 has-text-weight-medium has-text-centered">
            Tabla resumen con los permisos
            <b-tag
              icon-pack="fa"
              :style="{
                width: '7rem',
              }"
              :icon="iconByState('APROBADO')"
              :type="colorTypeByState('APROBADO')"
            >
              {{ "APROBADOS" }}
            </b-tag>
          </h2>
          <p class="has-text-centered is-size-6">
            Mes de {{ selectedMonth }} del año
            {{ selectedYear }}
          </p>
          <hr />
          <b-table
            :data="administrativePermitsApproved"
            :mobile-cards="true"
            striped
            narrowed
            paginated
            :per-page="10"
            :current-page="currentPage"
            @page-change="pageChanged"
          >
            <b-table-column v-slot="props" field="id" label="N°" centered>
              {{ (currentPage - 1) * 10 + props.index + 1 }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="person.run"
              label="RUT"
              centered
            >
              {{ props.row.person.run }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="person.name"
              label="Nombre"
              number
            >
              {{ props.row.person.name }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="person.last_name"
              label="Primer Apellido"
              number
            >
              {{ props.row.person.last_name }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="person.second_surname"
              label="Segundo Apellido"
              number
            >
              {{ props.row.person.second_surname }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="number_of_days"
              label="N° Días"
              number
            >
              {{ parseFloat(props.row.number_of_days) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="start_date"
              label="Desde"
              number
            >
              {{ formatDateToLocaleString(props.row.start_date) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="end_date"
              label="Hasta"
              number
            >
              {{ formatDateToLocaleString(props.row.end_date) }}
            </b-table-column>
          </b-table>
        </section>
        <footer
          class="modal-card-foot is-flex is-justify-content-space-between"
        >
          <b-button @click="isModalActive = false"> Cerrar </b-button>
          <div class="columns pr-3">
            <b-button
              v-if="false"
              type="is-pdf-color has-text-white"
              expanded
              icon-left="file-pdf"
              @click="generatePdf('transcription')"
            >
              Generar Transcripción
            </b-button>
            <b-button
              type="is-pdf-color has-text-white"
              icon-left="file-pdf"
              expanded
              @click="generatePdf('cover')"
            >
              Generar Carátula
            </b-button>
            <b-button
              v-if="false"
              type="is-pdf-color has-text-white"
              icon-left="file-pdf"
              @click="generatePdf('both')"
            >
              Generar Ambos
            </b-button>
          </div>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import {
  monthNames,
  formatDateToLocaleString,
  colorTypeByState,
  iconByState,
} from "../.././packs/utilities";

export default {
  props: {
    administrativePermits: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedMonth: null,
      selectedYear: new Date().getFullYear(),
      selectedStatus: "APROBADO",
      years: Array.from(
        { length: new Date().getFullYear() - 2020 + 1 },
        (_, i) => 2020 + i + (new Date().getMonth() === 11 ? 1 : 0)
      ),
      isModalActive: false,
      currentPage: 1,
      monthNames,
    };
  },
  computed: {
    administrativePermitsApproved() {
      const approvedPermits = Object.freeze(
        cloneDeep(this.administrativePermits)
      ).reduce((accumulator, document) => {
        // Filtrar y mapear los permisos aprobados.
        const approvedInDocument = document.adm_permits
          .filter((permit) => {
            const isApproved =
              permit.request_state.name === this.selectedStatus;
            const permitMonth = moment(permit.start_date).month() + 1;
            const permitYear = moment(permit.start_date).year();
            const isCorrectMonth =
              permitMonth === this.monthNames.indexOf(this.selectedMonth) + 1;
            const isCorrectYear = permitYear === this.selectedYear;

            return isApproved && isCorrectMonth && isCorrectYear;
          })
          .map((permit) => ({
            ...permit,
            person: document.person,
            createdAt: document.adm_permit_doc.request_date,
          }));
        return accumulator.concat(approvedInDocument);
      }, []);

      return approvedPermits.sort((a, b) => {
        return moment(a.createdAt) - moment(b.createdAt);
      });
    },
  },
  created() {
    this.selectedMonth = this.monthNames[new Date().getMonth()];
  },
  methods: {
    pageChanged(newPage) {
      this.currentPage = newPage;
    },
    openDocumentSelectionModal() {
      if (this.administrativePermits.length > 0) {
        this.isModalActive = true;
      }
    },
    async generatePdf(documentType) {
      if (documentType === "both") {
        await this.$emit("submit", {
          month: this.monthNames.indexOf(this.selectedMonth) + 1,
          year: this.selectedYear,
          status: this.selectedStatus,
          documentType: "transcription",
        });
        await this.$emit("submit", {
          month: this.monthNames.indexOf(this.selectedMonth) + 1,
          year: this.selectedYear,
          status: this.selectedStatus,
          documentType: "cover",
        });
      } else {
        if (this.selectedMonth && this.selectedYear && this.selectedStatus) {
          this.$emit("submit", {
            month: this.monthNames.indexOf(this.selectedMonth) + 1,
            year: this.selectedYear,
            status: this.selectedStatus,
            documentType,
          });
        }
      }
    },
    emitFilterEvent() {
      const monthNumber = this.monthNames.indexOf(this.selectedMonth) + 1;
      const formattedMonthNumber = monthNumber.toString().padStart(2, "0");

      const startDate = moment(
        `01-${formattedMonthNumber}-${this.selectedYear}`,
        "DD-MM-YYYY"
      ).format("DD-MM-YYYY");
      const endDate = moment(startDate, "DD-MM-YYYY")
        .endOf("month")
        .format("DD-MM-YYYY");

      this.$emit("filter", { startDate, endDate });
    },
    colorTypeByState,
    iconByState,
    formatDateToLocaleString,
  },
};
</script>

<style scoped>
.custom-border {
  border: 2rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: #e5e7eb;
  background-color: #fff;
  padding: 1rem;
  gap: 0.5rem;
}

.expanded-button {
  width: 100%;
}
@media (min-width: 1024px) {
  .modal-width {
    min-width: 960px;
  }
}
</style>
