<template>
  <div class="container">
    <div class="box">
      <AdministrativePermitsBreadcrums :breadcrumbs="breadcrumbs" />
      <h2 class="subtitle">Permisos administrativos:</h2>
      <h1 class="title">Resoluciones Mensuales</h1>
      <AdministrativePermitsTable
        admin-mode
        title="Solicitudes de permisos administrativos mensuales"
        :administrative-permits="administrativePermits"
        :permit-states="permitStates"
        :modal-form-status="modalFormStatus"
        @handleEditPermits="onEditPermits"
        @handleDownload="downloadPdf"
      >
        <template #filters>
          <div class="m-3">
            <AdministrativePermitsResolutionFilter
              :administrative-permits="administrativePermits"
              class="column is-narrow is-full-mobile"
              @filter="getAllAdministrativePermitsWithDates"
              @submit="newDownloadPdf"
            />
          </div>
        </template>
      </AdministrativePermitsTable>
      <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import jsPDF from 'jspdf'
import { svg2pdf } from 'svg2pdf.js';
import { monthNames, handleNetworkError } from "../.././packs/utilities";
import AdministrativePermitsBreadcrums from "./AdministrativePermitsBreadcrums.vue";
import AdministrativePermitsTable from "./AdministrativePermitsTable.vue";
import AdministrativePermitsResolutionFilter from "./AdministrativePermitsResolutionFilter.vue";
import {CalibriRegular} from "./fonts/calibri-regular";
import {CalibriBold} from "./fonts/calibri-bold";
import {HelveticaRegular} from "./fonts/helvetica-regular";

export default {
  components: {
    AdministrativePermitsBreadcrums,
    AdministrativePermitsTable,
    AdministrativePermitsResolutionFilter,
  },
  props: {
    permitStates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      breadcrumbs: [
        { href: "/life_record", label: "Menú principal" },
        { href: "/administrative-permits", label: "Permisos administrativos" },
        {
          href: "/administrative-permits/resolutions",
          label: "Resoluciones mensuales",
          isActive: true,
        },
      ],
      isLoading: false,
      modalFormStatus: false,
      administrativePermits: [],
      pdfAdministrativePermits: [],
      monthNames,
    };
  },

  async created() {
    const startDate = moment().startOf("month").format("DD-MM-YYYY");
    const endDate = moment().endOf("month").format("DD-MM-YYYY");
    await this.getAllAdministrativePermitsWithDates({ startDate, endDate });
  },
  methods: {
    handleNetworkError,
    async getAllAdministrativePermitsWithDates({ startDate, endDate }) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "/administrative-permits/permits.json",
          {
            params: {
              start_date: startDate,
              end_date: endDate,
              cost_center: 40,
            },
          }
        );
        if (response.status === 200 && response.data) {
          this.administrativePermits = response.data;
          if (response.data.length === 0) {
            this.$buefy.toast.open({
              message:
                "No se encontraron solicitudes de permisos administrativos para el rango de fechas seleccionado.",
              type: "is-danger",
              size: "is-medium",
              "has-icon": true,
              position: "is-bottom-right",
              "progress-bar": true,
              duration: 5000,
              queue: true,
            });
          } else {
            this.$buefy.toast.open({
              message: `Se encontraron ${response.data.length} solicitudes de permisos administrativos para el rango de fechas seleccionado.`,
              type: "is-success",
              size: "is-medium",
              "has-icon": true,
              position: "is-bottom-right",
              "progress-bar": true,
              duration: 5000,
              queue: true,
            });
          }
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al obtener los permisos administrativos";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
          role: "alert",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async downloadResolutionsPdf({ month, year, status, documentType }) {
      this.isLoading = true;
      if (documentType === "both") return;
      try {
        const response = await axios.get(
          `/administrative-permits/permits-resolution-pdf?month=${month}&year=${year}&state_name=${status}&document_type=${documentType}`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const filename = this.extractFilenameFromHeaders(response.headers);

        link.href = url;
        link.setAttribute("download", `${filename}.pdf`);
        link.click();
        this.$buefy.notification.open({
          message: `Se ha generado correctamente la ${
            documentType === "transcription" ? "transcripción" : "carátula"
          } de la resolución de permisos administrativos de ${
            this.monthNames[month - 1]
          } ${year}.`,
          type: "is-success",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          "progress-bar": true,
          queue: true,
        });
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al generar la resolución de permisos administrativos";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async downloadPdf({ documentId, personId }) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/administrative-permits/${personId}/permits-pdf/${documentId}.pdf`,
          { responseType: "blob" }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const filename = this.extractFilenameFromHeaders(response.headers);

        link.href = url;
        link.setAttribute("download", `${filename}.pdf`);
        link.click();
        this.$buefy.notification.open({
          message: `Se ha generado el permiso administrativo (IDS ${documentId}) exitosamente`,
          type: "is-success",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          "progress-bar": true,
          queue: true,
        });
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al generar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async newDownloadPdf({ month, year, status, documentType }){
      this.isLoading = true
      try{
        const response = await axios.get(
            `/administrative-permits/current-month-permits.json?month=${month}&year=${year}&state_name=${status}`
        );
        //Se crea el documento
        const doc = new jsPDF({
          orientation: "portrait", //Vertical
          unit: 'mm',
          format: [215.9, 279.4] //Tamaño carta
        });

        //Se cargan los tipos de fuentes
        doc.addFileToVFS("Calibri Regular.ttf", CalibriRegular());
        doc.addFont("Calibri Regular.ttf", "calibri", "normal");
        doc.addFileToVFS("Calibri Bold.ttf", CalibriBold());
        doc.addFont("Calibri Bold.ttf", "calibri-bold", "normal");
        doc.addFileToVFS("helvetica.ttf", HelveticaRegular());
        doc.addFont("helvetica.ttf", "helvetica", "normal")

        doc.setFont("calibri-bold", "normal")
        doc.setFontSize(10)

        doc.text("CONCEDE PERMISO CON GOCE DE REMUNERACIONES",107.5, 35, {align: "left"})
        const textWidth = doc.getTextWidth("CONCEDE PERMISO CON GOCE DE REMUNERACIONES");
        doc.setLineWidth(0.3);
        doc.line(107.5, 35.5, 107.5 + textWidth, 35.5);

        doc.text("SANTIAGO,",107.5, 45.5, {align: "left"})

        doc.text("VISTOS:",107.5, 60.5, {align: "left"})

        doc.setFontSize(11)
        doc.setFont("calibri")
        doc.text("El DFL. N° 29 de 2023, del Ministerio de",
            120.5, 60.5, { maxWidth: 155.9, align: "justify" });

        doc.text("Educación Pública, la ley N° 18.834 de 2005, las Resoluciones Universitarias N° 5321 de 2023, N° 5495 de 2023 y las Resoluciones N° 6 y N° 7 de 2019 de la contraloría General de la República.",
            30, 65.5, { maxWidth: 155.9, align: "justify" });

        doc.setFont("calibri-bold", "normal")
        doc.setFontSize(10)
        doc.text("RESUELVO:",107.5, 80.5, {align: "left"})

        doc.setFontSize(11)
        doc.setFont("calibri")

        doc.text("CONCÉDESE permiso con goce de",
            125.5, 80.5, { maxWidth: 155.9, align: "justify" });

        doc.text("remuneraciones a las personas de la FACULTAD DE INGENIERÍA, que se indica, a contar de la fecha que se señala:",
            30, 85.5, { maxWidth: 155.9, align: "justify" });

        doc.setFontSize(10)
        const columnas = ["NOMBRES", "RUT", "DÍA", "DESDE", "HASTA"];
        const filas = response.data.map((dato) => [dato.name, dato.rut, dato.day, dato.start_date, dato.end_date]);
        doc.autoTable({
          startY: 100.5,
          head: [columnas],
          theme: 'plain',
          styles: { halign: 'left', font: 'calibri', fontStyle: 'normal', fontSize: 9, textColor: [0, 0, 0], lineColor: [0, 0, 0]},
          headStyles: {halign: 'left', font: 'calibri-bold', fontStyle: 'normal', fontSize: 9, textColor: [0, 0, 0]},
          didDrawCell: (data) => {
            if (data.row.section === 'head') {
              const { cell, row, doc } = data;
              doc.setLineWidth(0.3);
              // Línea arriba del encabezado
              if (row.index === 0) {
                doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);
              }
              // Línea abajo del encabezado
              doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
            }
          },
          margin: { top: 35, left: 30, right: 30, bottom: 24.4},
          body: filas,
        })

        doc.setFont("calibri-bold", "normal")
        let start = doc.previousAutoTable.finalY

        if (start >= 230){
          doc.addPage()
          start = 15
        }

        doc.text("ANÓTESE, COMUNÍQUESE Y REGÍSTRESE",107.5, start+20, {align: "left"})


        if(documentType === "transcription"){
          start = start+20
          if(start >= 255){
            doc.addPage()
            start = 35
          }
          doc.text("DR. CRISTIAN VARGAS RIQUELME",30, start+10, {align: "left"})
          start = start+20
          if(start >= 235){
            doc.addPage()
            start = 35
          }
          doc.setFont("calibri")
          doc.text("Lo que transcribo a usted por orden del\n" +
              "Secretario General, para su conocimiento.\n" +
              "\n" +
              "Saluda atentamente,\n",107.5, start, {align: "left"})
          start = start+50
          if(start >= 245){
            doc.addPage()
            start = 35
          }
          doc.setFont("calibri-bold")
          doc.line(107.5, start, 107.5 + 67.5, start);
          doc.text("VANNESA RETAMAL GUTIÉRREZ\n" +
              "DIR. INF. Y GEST.DOCUMENTAL",141.25, start+3, {align: "center"})

        }
        else{
          start = start+50
          if(start >= 245){
            doc.addPage()
            start = 35
          }

          doc.setLineWidth(0.3);
          doc.line(107.5, start, 107.5 + 67.5, start);
          doc.text("DR. CRISTIAN VARGAS RIQUELME\n" +
              "DECANO FACULTAD DE INGENIERÍA\n",141.25, start+3, {align: "center"})
        }

        start = start+20
        if(start >= 230){
          doc.addPage()
          start = 35
        }

        doc.setFontSize(8)
        doc.text("Distribución", 30, start, {align: "left"})

        doc.setFont("calibri", "normal")
        doc.text("-Oficina Administrativa \n" +
            "-Unidad de partes, informaciones y archivo\n" +
            "-Secretaria Académica - Facultad de Ingeniería\n" +
            "-Control de asistencia\n" +
            "-Archivo General",30, start+3)

        for (var i = 0; i < doc.internal.getNumberOfPages(); i++) {
          doc.setPage(i)
          const response = await fetch('/fondo-usach-doc.svg');
          const svgText = await response.text();
          const parser = new DOMParser();
          const svgElement = parser.parseFromString(svgText, 'image/svg+xml').documentElement;

          // Convertir SVG a PDF y añadirlo al documento
          await svg2pdf(svgElement, doc, {
            x: 0,
            y: 0,
            width: 215.9,
            height: 279.4
          });
          doc.setFont("helvetica", "normal")
          doc.setFontSize(8.5)
          doc.setTextColor(166, 166, 166)
          doc.text("UNIVERSIDAD DE SANTIAGO DE CHILE\n" +
              "Av. Libertador Bernardo O´Higgins nº3363 - Estación Central - Santiago – Chile\n" +
              "www.usach.cl\n", 5, 265,{align: "left"})
        }
        //Guardar documento
        const todayDate = new Date().toISOString().split('T')[0]; // Obtiene la fecha actual en formato YYYY-MM-DD
        const date = new Date(year, month - 1, 1); // Crea una fecha con el año y mes proporcionados
        const monthName = date.toLocaleString('es-ES', { month: 'long' }); // Obtiene el nombre del mes en español

        const filenamePrefix = documentType === "transcription" ? "TRANSCRIPCION" : "CARATULA";
        const filename = `${todayDate}_${filenamePrefix}_RESOLUCION_PERMISO_ADM_${monthName.toUpperCase()}_${year}.pdf`;
        doc.save(filename)
        this.$buefy.notification.open({
          message: `Se ha generado correctamente la ${
              documentType === "transcription" ? "transcripción" : "carátula"
          } de la resolución de permisos administrativos de ${
              this.monthNames[month - 1]
          } ${year}.`,
          type: "is-success",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          "progress-bar": true,
          queue: true,
        });
        this.isLoading = false
      }
      catch (error){
        console.log(error)
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
            error.response && error.response.data.errors
                ? error.response.data.errors
                : "Ocurrió un error al generar el documento";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }

    },
    extractFilenameFromHeaders(headers) {
      const contentDisposition = headers["content-disposition"];
      const match = contentDisposition.match(/filename="(.+?)"/);

      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      }

      return null;
    },

    async onEditPermits({ personId, editedPermits, documentId }) {
      this.isLoading = true;
      this.modalFormStatus = false;

      try {
        const response = await axios.put(
          `/administrative-permits/${personId}/permits.json`,
          {
            person_id: personId,
            permits: editedPermits,
            document_id: documentId,
          }
        );
        if (response.status === 200) {
          const permitPosition = this.administrativePermits.findIndex(
            (permit) => {
              if (
                permit.adm_permit_doc.id === response.data.adm_permit_doc.id
              ) {
                return response.data;
              }
            }
          );
          this.administrativePermits.splice(permitPosition, 1, response.data);

          this.$buefy.notification.open({
            message: "Se ha actualizado el permiso administrativo exitosamente",
            type: "is-success",
            size: "is-medium",
            "has-icon": true,
            position: "is-bottom-right",
            "progress-bar": true,
            duration: 5000,
            queue: true,
          });
          this.modalFormStatus = true;
        }
      } catch (error) {
        if (this.handleNetworkError(error)) {
          return;
        }
        const errorMessage =
          error.response && error.response.data.errors
            ? error.response.data.errors
            : "Ocurrió un error al actualizar el permiso administrativo";

        this.$buefy.notification.open({
          message: errorMessage,
          type: "is-danger",
          size: "is-medium",
          "has-icon": true,
          position: "is-bottom-right",
          indefinite: true,
          queue: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .container {
    padding: 0;
  }
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
  }
  .position {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box {
    border-radius: 0%;
    box-shadow: none;
    min-height: calc(100vh - 64px);
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .position {
    width: 50%;
  }
}
</style>
